import { Injectable, EventEmitter, Injector } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { BaseService } from './base.service';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';

@Injectable({
  providedIn: 'root'
})
export class GeneralService extends BaseService {

  private apiPrefix: string = this.getApiBasePath() + '/general';
  //EventEmitter per l'aggiornamento del menu in caso di cambio ruoli/funzioni dell'utente
  public updateMenu:EventEmitter<any>;
  
  
  constructor(private http: Http, injector: Injector) { 
    super(injector);

    this.updateMenu = new EventEmitter<any>();
  }

  public loadMenuItems() {

    let options = this.getDefaultRequestOption();

    /*return this.http.get(this.apiPrefix + '/LoadUserMenu', options)
        .map((res: Response) => res.json())
        .catch(error => this.handleError(error.json()));*/

       

        return this.http.get(this.apiPrefix + '/LoadUserMenu', options)
        .toPromise()
        .then(response => {
            return response.json();
        })
        .catch(error => this.handleError(error.json()));
    }

}
