import { Injectable, Injector } from '@angular/core';
import { fuseConfig } from '../fuse-config';
import { HttpErrorResponse } from '@angular/common/http';

import { MyToasterService } from './toaster.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  public _toastSVC : MyToasterService;
  private authenticationService: AuthenticationService;

  constructor(injector: Injector) {
    this.authenticationService = injector.get(AuthenticationService);
   }

    /**
   * Ritorna l'end-point dell API
   */
  getApiBasePath():string {
    return fuseConfig.apiUrl + 'api';
  }

  handleError(error: HttpErrorResponse): Promise<any> {
    
    //Gestione errore 406  
    //console.log(error);
    //let bla = error.error.json();
    //let a = error.name; 

    if (error.status == 406){ 
      //console.error('Accesso negato o Token scaduto, error [406], Error.Text = ' + error.message);
      this._toastSVC.error('Token scaduto, Errore[406]');
      this.authenticationService.logout();
      return Promise.reject("Accesso negato o Token scaduto. Errore [406]");
    } else if (error.status == 403){ //Gestione errore 403
      //console.error('Accesso negato, error [403], Error.Text = ' + error.message);      
      this._toastSVC.error('Accesso negato, Errore [403]');
      return Promise.reject("Accesso negato. Errore [403]");
      }else if(error.status == 401){//Gestione errore 401 Unauthorized
        //console.error('Autorizzazione negata, error [401], Error.text = ', error.message);
        this._toastSVC.error('Autorizzazione negata, Errore[401]');
        return Promise.reject('Autorizzazione negata. Errore[401]');
    } else{
      //this._toastSVC.error(error.message);
    }
    
    return Promise.reject(error.message);
  }

  getDefaultRequestOption(){
    console.log("che sega");
    return this.authenticationService.getDefaultRequestOption();
  } 

  getBufferRequestOption(){
    return this.authenticationService.getBufferRequestOption();
  }
}
