import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';

import { fuseConfig } from './fuse-config';

import { AppComponent } from './app.component';
import { FuseFakeDbService } from './fuse-fake-db/fuse-fake-db.service';
import { FuseMainModule } from './main/main.module';
import { AppStoreModule } from './store/store.module';


import { HttpModule, Http }                 from '@angular/http';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { NgHttpLoaderModule } from 'ng-http-loader';


import {MyToasterService} from './services/toaster.service';
import {AuthenticationService} from './services/authentication.service';
import { CanActivatePage } from './services/canActivate';
import { GeneralService } from './services/general.service';
import { UserSVC } from './services/user.service';


const appRoutes: Routes = [
    {
        path        : 'apps',
        loadChildren: './main/content/apps/apps.module#FuseAppsModule',
        canActivateChild:[CanActivatePage]
    },
    {
        path        : 'pages',
        loadChildren: './main/content/pages/pages.module#FusePagesModule',
        canActivateChild:[CanActivatePage]
    },
    {
        path        : 'ui',
        loadChildren: './main/content/ui/ui.module#FuseUIModule',
        canActivateChild:[CanActivatePage]
    },
    {
        path        : 'services',
        loadChildren: './main/content/services/services.module#FuseServicesModule',
        canActivateChild:[CanActivatePage]
    },
    {
        path        : 'components',
        loadChildren: './main/content/components/components.module#FuseComponentsModule',
        canActivateChild:[CanActivatePage]
    },
    {
        path        : 'components-third-party',
        loadChildren: './main/content/components-third-party/components-third-party.module#FuseComponentsThirdPartyModule',
        canActivateChild:[CanActivatePage]
    },
    {
        path:'skipper',
        loadChildren: './main/content/skipper/skipper.module#SkipperModule',
        canActivateChild:[CanActivatePage]
    },
    {
        path      : '**',
        redirectTo: 'apps/dashboards/project' //pages/auth/login'
    }
];



@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule, 
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FuseFakeDbService, {
            delay             : 0,
            passThruUnknownUrl: true
        }),

        // Fuse Main and Shared modules
        FuseModule.forRoot(fuseConfig),
        FuseSharedModule,

        AppStoreModule,
        FuseMainModule,
        HttpModule,
        ToastrModule.forRoot(),
        ToastContainerModule,
        NgHttpLoaderModule
        
    ],
    providers :[
        AuthenticationService,
        MyToasterService,
        CanActivatePage,
        GeneralService,
        UserSVC
    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
