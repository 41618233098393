import { Injectable } from '@angular/core';
import { ToastrService} from 'ngx-toastr';

@Injectable()
export class MyToasterService {
   
   constructor(public toastrService: ToastrService) { 
   }


  public success(msg:string, title:string = ""){
      this.toastrService.success(msg, title);
  }

   public warning(msg:string, title:string = ""){
      this.toastrService.warning(msg, title);
  }

   public error(msg:string, title:string = ""){
      this.toastrService.error(msg, title);
  }

   public info(msg:string, title:string = ""){
      this.toastrService.info(msg, title);
  }
}