import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs/Observable";
import { AuthenticationService } from "./authentication.service";

@Injectable()
export class CanActivatePage implements CanActivateChild {
  constructor(private _authenticationService: AuthenticationService, private _router: Router) {
    
  }
 
  canActivateChild(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean>|Promise<boolean>|boolean {

    let isLogged = this._authenticationService.isLoggedUser(); 

    /*if(this._router.url === "/pages/auth/login")
    {
      this._router.navigate(['auth/login']);
    }
    
    if(this._router.url === "/pages/auth/forgot-password")
    {
      this._router.navigate(['/pages/auth/forgot-password']);
    }*/

    
      

    if(!isLogged){
       this._router.navigate(['auth/login']);
     }
    return true;
  }
}