import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions, ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { fuseConfig } from '../fuse-config';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';



@Injectable()
export class AuthenticationService {
    public token: string;
    public tokenSaved: boolean = false;
    private result: boolean = false;


    constructor(private _http: Http, private _router:Router) {
        // set token if saved in local storage
        this.token = localStorage.getItem('access_token');
    }


    /**
     * Effettua la validazione delle credenziali e restituisce il token in caso positivo
     * @param event :Event
     * @param username :string
     * @param password :string
     */
    login(event, username, password): Observable<boolean> {
        event.preventDefault(); 

        let url = fuseConfig.apiUrl;
        let body = "username=" + username.value + "&password=" + password.value + "&grant_type=password";
        let headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
        let options = new RequestOptions({ headers: headers });


        return this._http.post(url + 'token', body, options)
            .map((response: Response) => {
                // login successful if there's a jwt token in the response
                //let token = response.json() && response.json().token;
                let res = response.json();
                let token = "token";
                if (res.access_token != undefined) {

                    localStorage.setItem('access_token', res.access_token);
                    localStorage.setItem('expires_in', res.expires_in);
                    localStorage.setItem('token_type', res.token_type);
                    localStorage.setItem('userName', res.userName);
                    localStorage.setItem('fullName', res.fullName);
                    localStorage.setItem('userId', res.userId);
                    localStorage.setItem('role', res.roleName)
                    this.token = localStorage.getItem('access_token');

                    console.log("role " + res.roleName);
                    // return true to indicate successful login
                    return true;
                } else {
                    // return false to indicate failed login
                    return false;
                }
            });




    }

    /**
     * Controlla se il token è valido e reagisce di conseguenza
     * Se non è valido riporta l'utente alla pagina di login 
     * effettuando il logoud e ripulendo il localStorage
     */
    isLoggedUser(fromLogin :boolean = false ){
        console.log("controllo logged");
        return this.isValidToken().subscribe(
        response => {
                if ( !response ) {
                    console.log("perform logout");
                   this.logout();
                }else{
                    fromLogin ? this._router.navigate(['apps/dashboards/project']) : null;
                }
            },
        error => {
            this.logout();
        }
        );
    }

    /**
     * Effettua una chiamata verso il server 
     * se la risposta è positiva il token è valido
     * altrimenti il token non è valido 
     */
   
    isValidToken():Observable<boolean>{
        let options = this.getDefaultRequestOption();
        return this._http.get(fuseConfig.apiUrl + 'api/General/CheckTokenValidity', options)
        .map(
            data =>  { 
                let result = data.json() as boolean;
                return result;
            }
        );

       
    }

    /**
     * Ritorna un Observable con un array di ID delle funzioni collegate all'utente 
     */
    getUserFunctions():Observable<number[]>{
        let options = this.getDefaultRequestOption();
        return this._http.get(fuseConfig.apiUrl  + 'api/users/GetUserFunctions', options).map(
            data => {
                let functions = data.json() as number[]; 
                //console.debug('AuthenticationService.getUserFunctions() response: ', functions);
                return functions;
            }
        );
    }

    isAdmin():boolean{
        let userRole = localStorage.getItem('role');
        if(userRole == 'Administrator' || userRole == 'SAdministrator')
        {
            return true;
        }
        else
        {
            return false;
        }
    }
    userIsLogged():void {
        console.log("so passato per controllo token");
        this.token = localStorage.getItem('access_token');
        console.log("token:" + this.token);
        if(this.token == "")
       {
           this.logout();
       }

    }
        
    

    /**
     * Effettua il logout e pulisce il locaStorage
     */
    logout(): void {
        // clear token remove user from local storage to log user out
        this.token = null;
        localStorage.removeItem('currentUser');
        localStorage.clear();
        console.log("logout");
        this._router.navigate(['/pages/auth/login']);
    }

    /**
     * Restituisce l'oggetto "options" da utilizzare per le richieste HTTP
     * verso risorse che richiedono autenticazione
     */
    public getDefaultRequestOption(): RequestOptions {

        let headers = new Headers({ 'Authorization': 'Bearer ' + this.token });
        let options = new RequestOptions({ headers: headers });

        return options;
    }

    public getDefaultHeaders(): HttpHeaders {

        let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
       // let options = new RequestOptions({ headers: headers });

        return headers;
    }

    public getBufferRequestOption(): RequestOptions {

        let headers = new Headers({ 'Authorization': 'Bearer ' + this.token });
        let responseType = ResponseContentType.ArrayBuffer;
        let options = new RequestOptions({ headers: headers, responseType: responseType });
        

        return options;
    }

    public getCurrentUserName(): string {
        return localStorage.getItem('fullName');
    }

    public getLoggedUserId(): string {
        return localStorage.getItem('userId');
    }


}


enum SmartFeeFunctions
{
    // Registry            = 1,
    // Flows               = 2,
    // Queries             = 3,
    // Report              = 4,
    // Historic            = 5,
    // System              = 6,
    SubscribersList		= 7,
    ReferentsList		= 8,
    RecycledList		= 9,
    LoadFile			= 10,
    Received			= 11,
    Missed				= 12,	
    CreditHandler		= 13,
    QueryManager		= 14,
    CreditReferent		= 15,
    Discontinued		= 17,
    NewSubscribers		= 18,
    SubscribersReferent	= 19,
    Log					= 20,
    UserList			= 21,
    RoleList			= 22,
    ActiveFiles			= 23,
    Bank				= 24,
    ConfigDataFlowFile	= 25,
    Monitoring			= 26,
    Variazioni			= 27,
    Dictionary			= 28,
    Places				= 29
}